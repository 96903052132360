import { useMemo, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { GET_CMS_PRODUCTS_BY_CATEGORY } from '@graphql/queries/getCmsProductByCategory'
import { useQuery } from '@apollo/client'

export const useCmsProduct = () => {
  const isMobile: boolean = useMediaQuery('(max-width:767px)')
  const [value, setValue] = useState('best-seller')
  const { data: bestSellerProductsRes } = useQuery(
    GET_CMS_PRODUCTS_BY_CATEGORY,
    {
      variables: {
        filters: {
          category_id: { eq: 187 }
        }
      }
    }
  )
  const { data: newProductsRes } = useQuery(GET_CMS_PRODUCTS_BY_CATEGORY, {
    variables: {
      filters: {
        category_id: { eq: 188 }
      }
    }
  })

  const bestProducts = useMemo(() => {
    return (
      bestSellerProductsRes?.products?.items?.map((x) => ({
        id: x.id,
        link: `${x.url_key}.html`,
        name: x.name,
        imgurl: x.main_image.url,
        hover_image: x.hover_image,
        price: x.price_range?.minimum_price?.final_price?.value
      })) || []
    )
  }, [bestSellerProductsRes])

  const newProducts = useMemo(() => {
    return (
      newProductsRes?.products?.items?.map((x) => ({
        id: x.id,
        link: `${x.url_key}.html`,
        name: x.name,
        imgurl: x.main_image.url,
        hover_image: x.hover_image,
        price: x.price_range?.minimum_price?.final_price?.value
      })) || []
    )
  }, [newProductsRes])

  const handleChange = (event: any, param: string) => {
    setValue(param)
  }

  return {
    bestProducts,
    newProducts,
    isMobile,
    value,
    handleChange
  }
}
