import { gql, DocumentNode } from '@apollo/client'
import { price_range } from '@graphql/fragment/priceRange'
import { price_tiers } from '@graphql/fragment/priceTiers'

export const GET_CMS_PRODUCTS_BY_CATEGORY: DocumentNode = gql`
  query getCmsProductByCategory($filters: ProductAttributeFilterInput) {
    products(pageSize: 15, currentPage: 1, filter: $filters) {
      items {
        id
        main_image: image {
          label
          url
        }
        url_key
        name
        hover_image(width: 384, height: 512)

        price_range {
          ...price_range
          __typename
        }
        price_tiers {
          ...price_tiers
          __typename
        }
      }
      total_count
    }
  }
  ${price_range}
  ${price_tiers}
`
